import { defineComponent } from "vue";

<template>
  <form class="cont" @submit.prevent="submit" v-if="isLoaded">
    <h3>Create Provider</h3>
    <div class="input-set">
      <label for="practice">Practice</label>
      <select v-model="selected" id="practice">
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </div>
    <p>Insert provider info in csv format.</p>
    <p>Example:</p>
    <pre>
      Kenneth,Christensen,kenneth@ascendanttracker.com
      Hailey,Krey,hailey@ascendanttracker.com
    </pre>
    <textarea v-model="emailsRaw" class="input-box" />
    <button class="submit-button">Submit</button>
  </form>
  <div v-else><p>Loading</p></div>
</template>

<script lang="ts">
import ApiInterface from "@/api/ApiInterface";
import { Practice } from "@/store/Practice";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { extractProviderRow } from "./CreateProviderHelpers";

export default defineComponent({
  props: {
    id: String,
  },
  setup(props) {
    console.log(`Got id of ${props.id}`);

    const router = useRouter();

    const store = useStore();
    const practices = computed(() => store.state.practices);

    const options = computed(() =>
      practices.value.map((p: Practice) => {
        return {
          text: p.name,
          value: p.id,
        };
      })
    );

    const selected = ref(props.id);
    if (!selected.value) {
      selected.value = options.value[0].id;
    }

    const providerDataRaw = ref("");

    const submit = async () => {
      let parsedEmails: ReturnType<typeof extractProviderRow>[];
      try {
        parsedEmails = providerDataRaw.value
          .split("\n")
          .map(extractProviderRow);
      } catch (e) {
        console.log(e);
        alert("Something went wrong while creating those providers: " + e);
        return;
      }

      if (parsedEmails.length == 0) {
        alert("must specificy at least one email");
        return;
      }

      if (await ApiInterface.createProviders(selected.value!, parsedEmails)) {
        store.dispatch("getProviders");
        router.push({
          name: "PracticeDetails",
          params: { id: selected.value! },
        });
      } else {
        alert("Something went wrong");
      }
    };

    const isLoaded = computed(() => store.state.practices.length > 0);

    return {
      options,
      selected,
      emailsRaw: providerDataRaw,
      submit,
      isLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
.input-set {
  padding: 0.3rem;
}

.input-set label {
  width: 200px;
  display: inline-block;
}

.input-set select {
  width: 400px;
}

.input-box {
  width: 600px;
  display: block;
}

.submit-button {
  color: black;
  padding: 0.4rem;
  margin-top: 1rem;
}
</style>
