
import ApiInterface from "@/api/ApiInterface";
import { Practice } from "@/store/Practice";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    id: String,
  },
  setup(props) {
    console.log(`Got id of ${props.id}`);

    const router = useRouter();

    const store = useStore();
    const practices = computed(() => store.state.practices);

    const name = ref("");
    const address = ref("");
    const city = ref("");
    const state = ref("");
    const zipCode = ref("");
    const phone = ref("");

    const error = ref("");

    const duplicates = ref([]);

    const validate = (): boolean => {
      if (name.value.trim().length == 0) {
        error.value = "You must provide a practice name";
        return false;
      }

      error.value = "";
      return true;
    };

    const submit = async () => {
      if (!validate()) {
        return;
      }

      try {
        const newPracticeId = await ApiInterface.createPractice(
          name.value,
          address.value,
          city.value,
          state.value,
          zipCode.value,
          phone.value
        );

        await store.dispatch("getPractices");
        router.push({
          name: "PracticeDetails",
          params: { id: newPracticeId },
        });
      } catch (e) {
        console.log(e);
        alert("Something went wrong");
      }
    };

    const isLoaded = computed(() => store.state.practices.length > 0);

    if (!isLoaded.value) {
      store.dispatch("getPractices");
    }

    return {
      submit,
      isLoaded,
      name,
      address,
      state,
      city,
      zipCode,
      phone,
      error,
      duplicates
    };
  },
});
