import { defineComponent } from "vue";

<template>
  <form class="cont" @submit.prevent="submit" v-if="isLoaded">
    <h3>Create Practice</h3>
    <p>
      To create a new practice, fill out the following form and press submit.
      Make sure you're not creating a duplicate!
    </p>

    <div class="input-set">
      <label for="name">Practice Name</label>
      <input type="text" id="name" v-model="name" />
    </div>

    <div class="input-set">
      <label for="address">Street Address</label>
      <input type="text" id="address" v-model="address" />
    </div>

    <div class="input-set">
      <label for="city">City</label>
      <input type="text" id="city" v-model="city" />
    </div>

    <div class="input-set">
      <label for="state">State</label>
      <input type="text" id="state" v-model="state" />
    </div>

    <div class="input-set">
      <label for="zipCode">Zip Code</label>
      <input type="text" id="zipCode" v-model="zipCode" />
    </div>

    <div class="input-set">
      <label for="phone">Contact Phone Number</label>
      <input type="text" id="phone" v-model="phone" />
    </div>

    <div class="possible-duplicates" v-if="duplicates.length > 0">
      <p>
        These practices look similar to the one you are creating; make sure
        you're not creating a duplicate.
      </p>
      <ul>
        <li v-for="dup in duplicates" :key="dup.id">
          <router-link
            :to="{ name: 'practiceDetails', params: { id: dup.id } }"
            >{{ dup.name }}</router-link
          >
        </li>
      </ul>
    </div>

    <p class='error-message' v-if="error.length > 0">{{ error }}</p>
    <button class='submit-button'>Submit</button>
  </form>
  <div v-else><p>Loading</p></div>
</template>

<script lang="ts">
import ApiInterface from "@/api/ApiInterface";
import { Practice } from "@/store/Practice";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    id: String,
  },
  setup(props) {
    console.log(`Got id of ${props.id}`);

    const router = useRouter();

    const store = useStore();
    const practices = computed(() => store.state.practices);

    const name = ref("");
    const address = ref("");
    const city = ref("");
    const state = ref("");
    const zipCode = ref("");
    const phone = ref("");

    const error = ref("");

    const duplicates = ref([]);

    const validate = (): boolean => {
      if (name.value.trim().length == 0) {
        error.value = "You must provide a practice name";
        return false;
      }

      error.value = "";
      return true;
    };

    const submit = async () => {
      if (!validate()) {
        return;
      }

      try {
        const newPracticeId = await ApiInterface.createPractice(
          name.value,
          address.value,
          city.value,
          state.value,
          zipCode.value,
          phone.value
        );

        await store.dispatch("getPractices");
        router.push({
          name: "PracticeDetails",
          params: { id: newPracticeId },
        });
      } catch (e) {
        console.log(e);
        alert("Something went wrong");
      }
    };

    const isLoaded = computed(() => store.state.practices.length > 0);

    if (!isLoaded.value) {
      store.dispatch("getPractices");
    }

    return {
      submit,
      isLoaded,
      name,
      address,
      state,
      city,
      zipCode,
      phone,
      error,
      duplicates
    };
  },
});
</script>

<style lang="scss" scoped>
.input-set {
  padding: 0.3rem;
}

.input-set label {
  width: 200px;
  display: inline-block;
}

.input-set input {
  width: 400px;
}

.submit-button {
  color: black;
  padding: 0.4rem;
  margin-top: 1rem;
}
</style>
